import img1 from './assets/reading2.jpg';
import img2 from './assets/dominos.jpg';
import img3 from './assets/listening2.jpg';
import { Link } from 'react-router-dom';

type BlogListProps = {
    onPostClick: (id: number) => void;
};


const posts = [
  {
    id: 1,
    title: 'How to build reading stamina?',
    description:
      'Reading stamina is a child’s ability to focus and read independently for long-ish periods of time without being distracted or without distracting others. Find out how you can help your child develop reading stamina.',
    imageUrl: img1,
    date: 'June 16, 2024',
    datetime: '2024-06-16',
    category: { title: 'Reading', href: '#' },
    author: {
      name: 'Donald Fichner',
      role: 'CEO U&ME',
      href: '#',
      imageUrl:
      'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    },
  },
  {
    id: 2,
    title: 'Tips to practise English at home',
    description:
      'Practicing English speaking at home is an excellent way to improve your fluency and confidence. Here are some practical steps you can take:',
    imageUrl: img2,
    date: 'August 03, 2024',
    datetime: '2024-07-03',
    category: { title: 'Self practise', href: '#' },
    author: {
      name: 'Michael Foster',
      role: 'Co-Founder / CTO',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    id: 3,
    title: 'Art of listening and understanding',
    description:
      'Listening is the gateway to understanding; master this art, and every conversation becomes a rich opportunity to learn, grow, and connect deeply with others.',
    imageUrl: img3,
    date: 'Mar 16, 2024',
    datetime: '2020-03-16',
    category: { title: 'Listening', href: '#' },
    author: {
      name: 'Jimmy Ann',
      role: 'Founder TedEx',
      href: '#',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/2/23/Square_Sized_Photo.png',
    },
  },
];

export default function Bloglist() {
  return (
    <div id="blog" className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">From the blog</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
          Here are some worthwhile blogs to explore and make the most of your time.
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article key={post.id} className="flex flex-col items-start justify-between">
              <div className="relative w-full">
                <img
                  alt=""
                  src={post.imageUrl}
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                <div className="absolute inset-0 bg-indigo-400 mix-blend-multiply rounded-2xl overflow-hidden " />

              </div>
              <div className="max-w-xl">
                <div className="mt-8 flex items-center gap-x-4 text-xs">
                  <time dateTime={post.datetime} className="text-gray-500">
                    {post.date}
                  </time>
                  <span
                    className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                  >
                    {post.category.title}
                  </span>
                </div>
                <div className="group relative">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <Link to={`/blog/${post.id}`}>
                      <span className="absolute inset-0" />
                      {post.title}
                    </Link>
                  </h3>
                  <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
                  <Link to={`/blog/${post.id}`}>Read more</Link>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}