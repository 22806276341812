const stats = [
    { id: 1, name: 'Daily English practice at home, such as reading together with parents or practicing vocabulary, can support and enhance classroom learning.', value: '10-20 minutes' },
    { id: 2, name: 'People worldwide speak English, making it the most commonly spoken second language globally.',value: '1.5 Billion'},
    { id: 3, name: 'Top multinational companies require English proficiency', value: '90%' },
  ]
  export default function Example() {
    return (
      <div className="bg-indigo-300 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
            {stats.map((stat) => (
              <div key={stat.id} className="mx-auto flex max-w-xs flex-col gap-y-4">
                <dt className="text-base font-semibold leading-7 text-gray-800">{stat.name}</dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-Gray-900 sm:text-5xl">{stat.value}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    )
  }
