// BlogPost.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import teamimg from './assets/reading2.jpg';
import selfpracticeimg from './assets/dominos.jpg';
import listenimg from './assets/listening2.jpg'

import { useParams } from 'react-router-dom';
// Define the Post type
type Post = {
    id: number;
    title: string;
    category_title:string;
    description_p1: string;
    description_p2: string;
    description_p3: string;
    description_p4: string;
    description_p5: string;
    description_p6: string;
    description_p7: string;
    imageUrl: string;
    picture_quote:string;
    picture_bottom_title:string;
};

// Example posts array
const posts: Post[] = [
    {
    id: 1,
    title: 'How to Build Reading Stamina?',
    category_title:'Reading',
    imageUrl: teamimg,
    picture_quote: 'Reading stamina is a child’s ability to focus and read independently for long-ish periods of time without being distracted or without distracting others. Find out how you can help your child develop reading stamina.',
    picture_bottom_title:"By: Reading Rockets",
    description_p1: " Teachers often think about a student’s reading stamina. Reading stamina is a child’s ability to focus and read independently for long-ish periods of time without being distracted or without distracting others. Reading stamina is something that parents can help students develop. Here’s how: ",
    description_p2: "1. Choose “just right” books",
    description_p3:"If your child is at a stage of being able to read alone, help him choose books that he is able to read independently. This means he should be able to decode almost every word in the book correctly. In this situation, avoid using books that are too difficult to read alone. If your child will be reading with you, choose books that are lively and engaging.",
    description_p4: "2. Set reasonable goals",
    description_p5:"Most toddlers and preschoolers find it difficult to sit for long periods of time, even with the most engaging book! When starting out, limit book time to just a few minutes and work up from there. For elementary aged readers, consider starting with 10-15 minutes of reading time, and work up from there. Add a few minutes to your reading time every week or so.",
    description_p6:"3. Celebrate progress  ",
    description_p7:"Without getting too caught up on the number of minutes spent reading, celebrate the time that is spent reading. Share your favorite parts of books read, plan the next visit to the library, and share progress with other family members.Spending longer periods of time reading means fewer interruptions and more time reading what you love. As your child moves into higher grades, having reading stamina will help your child navigate the longer texts and assignments. Using these tips can help develop more stamina in your reader",

    },
    {
      id: 2,
      title: 'Tips to practise English at home',
      category_title:'Speaking self practice',
      imageUrl: selfpracticeimg,
      picture_quote: 'Practice does not make you perfect. Only right practice makes you perfect.',
      picture_bottom_title:'– Vince Lombardi',
      description_p1: "Practicing English speaking at home is an excellent way to improve your fluency and confidence. Here are some practical steps you can take:",
      description_p2: "Self-Talk & Reading Aloud",
      description_p3:" Practice thinking and speaking in English by narrating your daily activities or reading aloud. This helps with fluency and pronunciation.",
      description_p4: "Language Immersion:",
      description_p5:"Surround yourself with English through movies, music, and podcasts. This enhances listening skills and familiarizes you with natural speech patterns.",
      description_p6:"Practice with a Partner & Shadowing:",
      description_p7:"Engage in regular conversations with a partner and mimic native speakers’ speech. This builds confidence and improves your accent.",
      
      },
      {
        id: 3,
        title: 'The Art of Listening in English',
        category_title:'Listening',
        imageUrl: listenimg,
        picture_quote: '',
        picture_bottom_title:"",
        description_p1: "",
        description_p2: "Immerse Yourself in English Daily:",
        description_p3: "Surround yourself with English by incorporating it into your daily routine. Listen to English-language media such as movies, TV shows, podcasts, and music. This regular exposure will help you become more comfortable with the rhythm, intonation, and vocabulary used in different contexts",
        description_p4: "Practice Active Listening Techniques: ",
        description_p5:"When listening to English, focus not just on the words but also on the pronunciation, tone, and context. Take notes or mentally repeat key phrases. This active engagement will improve your understanding and help you retain what you’ve heard. Try to summarize the content afterward to check your comprehension.One of the challenges of listening in English is dealing with various accents. English is spoken worldwide, and each region brings its own unique accent. While this diversity can be daunting, exposure to different accents will increase your adaptability and confidence. The more you listen to different accents, the easier it will become to understand English in any context.",
        description_p6:"Be Consistent and Patient:",
        description_p7:"Building strong listening skills takes time and persistence. Keep practicing regularly, even when it feels challenging. Over time, your ability to understand and engage in English conversations will improve, making you a more effective communicator.",
        },
];

export default function Blogpage() {
    const { id } = useParams<{ id: string }>();

    // Find the post based on the ID from the URL
    const post = posts.find((post) => post.id === parseInt(id || '', 10));

    // Handle case where post might not be found
    if (!post) {
        return <div>Post not found</div>;
    }

            return (
              <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                  <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-4">
                      <div className="relative overflow-hidden rounded-3xl bg-gray-00 px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
                        <img
                          alt={post.title}
                          src={post.imageUrl}
                          className="absolute inset-0 h-full w-full object-cover brightness-15 saturate-25"
                        />
                        {/* <div className="absolute inset-0 bg-gray-400 mix-blend-multiply" /> */}
                        <div className="absolute inset-0 bg-indigo-300 mix-blend-multiply" />

                        <div
                          aria-hidden="true"
                          className="absolute left-1/2 top-1/2 -ml-16 -translate-x-1/2 -translate-y-1/2 transform-gpu blur-3xl"
                        >
                          <div
                            style={{
                              clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-0"
                          />
                        </div>
                        <figure className="relative isolate">
                          {/* <img alt="" src="https://tailwindui.com/img/logos/workcation-logo-white.svg" className="h-12 w-auto" /> */}
                          <blockquote className="mt-6 text-xl font-semibold leading-8 text-white">
                            <p>
                             {post.picture_quote}
                            </p>
                          </blockquote>
                          <figcaption className="mt-6 text-sm leading-6 text-gray-300">
                            <strong className="font-semibold text-white">{post.picture_bottom_title}</strong>
                          </figcaption>
                        </figure>
                      </div>
                    </div>
                    <div>
                      <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                        <p className="text-base font-semibold leading-7 text-indigo-600">{post.category_title}</p>
                        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                          {post.title}
                        </h1>
                        <div className="max-w-xl">
                          <p className="mt-6">
                            {post.description_p1}
                          </p>
                          <p className="mt-4 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                            {post.description_p2}
                         </p>
                          <p className="mt-4">
                            {post.description_p3}
                         </p>
                         <p className="mt-4 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                            {post.description_p4}
                         </p>
                         <p className="mt-4">
                            {post.description_p5}
                         </p>
                         <p className="mt-4 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                            {post.description_p6}
                         </p>
                         <p className="mt-4">
                            {post.description_p7}
                         </p>
                        </div>
                      </div>
                      <div className="mt-10 flex">
                      <Link
                        to="/"
                        className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                        Close this article and return home
                    </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }