import prod1 from'./assets/hasiqmegha1.jpg'
import prod2 from'./assets/girlsgroupic.jpg'
const people = [
    {
      name: 'Curious Starters',
      role: '6 to 11 years',
      imageUrl:prod1,
      bio: 'This program is tailored for beginners who are eager to learn English through friendly and effective methods based on global practices. It is suitable for young learners at any level and is customized to ensure you don’t relearn what you already know. ',
      xUrl: '#',
      linkedinUrl: '#',
    },
    {
        name: 'High Flyers',
        role: '12 to 15 years',
        imageUrl:prod2,
        bio: 'This course enhances English and public speaking skills with engaging activities for both intermediate and advanced learners. Build vocabulary, refine grammar, and master effective communication and presentations for increased confidence and impact.',
        xUrl: '#',
        linkedinUrl: '#',
      },
    // More people...
  ]

  export default function Courseex() {
    return (
      <div id="courses" className="bg-white py-24 md:py-32 lg:py-40">
        <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Courses</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Our courses are carefully crafted with structured curriculam which covers all that is required to become a fluent and confident english speaker.
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2"
          >
            {people.map((person) => (
              <li key={person.name}>
                <div className="relative inset-0 flex-none rounded-2xl overflow-hidden">
                <img alt=""
                src={person.imageUrl}
                className="aspect-[3/2] w-full rounded-2xl object-cover overflow-hidden" />
                <div className="absolute inset-0 bg-indigo-400 rounded-2xl overflow-hidden mix-blend-multiply" />
                </div>
                <h3 className="mt-6 text-lg font-semibold leading-8 text-gray-900">{person.name}</h3>
                <p className="text-base leading-7 font-semibold text-gray-600">{person.role}</p>
                <p className="mt-4 text-base font-semibold leading-7 text-gray-600">{person.bio}</p>
                {/* <ul role="list" className="mt-6 flex gap-x-6">
                  <li>
                    <a href={person.xUrl} className="text-gray-400 hover:text-gray-500">
                      <span className="sr-only">X</span>
                      <svg fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="h-5 w-5">
                        <path d="M11.4678 8.77491L17.2961 2H15.915L10.8543 7.88256L6.81232 2H2.15039L8.26263 10.8955L2.15039 18H3.53159L8.87581 11.7878L13.1444 18H17.8063L11.4675 8.77491H11.4678ZM9.57608 10.9738L8.95678 10.0881L4.02925 3.03974H6.15068L10.1273 8.72795L10.7466 9.61374L15.9156 17.0075H13.7942L9.57608 10.9742V10.9738Z" />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href={person.linkedinUrl} className="text-gray-400 hover:text-gray-500">
                      <span className="sr-only">LinkedIn</span>
                      <svg fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="h-5 w-5">
                        <path
                          d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                          clipRule="evenodd"
                          fillRule="evenodd"
                        />
                      </svg>
                    </a>
                  </li>
                </ul> */}
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
