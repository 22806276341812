import {
  HeartIcon,
  MicrophoneIcon,
  RocketLaunchIcon,
  StarIcon,
  TruckIcon,
  ScaleIcon,
  TagIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Hands on learning',
    description: 'Learners will participate in interactive sessions such as role-playing, simulated play, group activities, jam sessions, and many more engaging experiences.',
    icon: RocketLaunchIcon,
  },
  {
    name: 'Modern Learning Environment',
    description: 'Enjoy a fully air-conditioned classroom with top-tier design and world-class quality standards.',
    icon: StarIcon,
  },

  {
    name: 'Hybrid Learning',
    description: 'Blended offline and online classes are available, allowing learners to maintain their routine while minimizing commuting.',
    icon: ScaleIcon,
  },
  {
    name: 'Affordable price',
    description: 'A place where quality learning will be available at an very moderate and affodable price',
    icon: TagIcon,
  },
  {
    name: 'Talent display',
    description: 'Learners will showcase their acquired skills at regular intervals during the course.',
    icon: MicrophoneIcon,
  },
  {
    name: 'Only After School',
    description: 'It is an after school program. Classes will be conducted only in the evening and weekends so that your regular routine will not be affected',
    icon: HeartIcon,
  },
  {
    name: 'Limited group size',
    description: 'Small group size with limited students will only be enrolled in a batch',
    icon: UserGroupIcon,
  },
  {
    name: 'Pick up and drop service',
    description: 'In house van pick and drop facility available on request',
    icon: TruckIcon,
  },
]

export default function Featureex() {
  return (
    <div id="features" className="bg-indigo-700">
      <div className="mx-auto max-w-4xl px-6 py-24 sm:py-32 lg:max-w-7xl lg:px-8 lg:py-40">
        <h2 className="text-4xl font-bold tracking-tight text-white">Features of our program</h2>
        <p className="mt-6 max-w-3xl text-lg leading-8 text-indigo-200">
        Our specialized techniques turn communication barriers into stepping stones, guiding learners to become self-assured and impactful speakers
        </p>
        <div className="mt-20 grid grid-cols-1 gap-16 sm:grid-cols-2 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name}>
              <div>
                <span className="flex h-12 w-12 items-center justify-center rounded-xl bg-white bg-opacity-10">
                  <feature.icon aria-hidden="true" className="h-8 w-8 text-white" />
                </span>
              </div>
              <div className="mt-6">
                <h3 className="text-lg font-semibold leading-8 text-white">{feature.name}</h3>
                <p className="mt-2 text-base leading-7 text-indigo-200">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
